header.Header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-logo-wrap svg {
        height: 30px;
    }
    nav {
        
        ul { 
            display: flex;
            justify-content: flex-end;
            gap: 20px;
            list-style: none; 
        }

    }
}


.PageLayout {
    main { padding: 20px; }
}