.PaginationControls {
    display: flex;
    gap: 20px;
    align-items: center;
}

.UserTableControlsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}